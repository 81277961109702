/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

.object-fit-contain {
  object-fit: contain;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-normal {
  cursor: default;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

.table-nowrap th,
.table-nowrap td {
  white-space: break-spaces !important;
}

th,
td {
  text-align: center !important;
}

.form-switch {
  width: fit-content;
  margin: 0 auto;
}
